import React, { useEffect, useState } from 'react';
import client from '../../client.js';
import './DescriptionAccueil.css';

function DescriptionAccueil() {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    client
      .fetch(`*[_type == "description" && title == "Description Romain"]{
        title,
        content
      }`)
      .then((data) => setDescription(data[0]))
      .catch(console.error);
  }, []);

  if (!description) return <div>Loading...</div>;

  return (
    <div className='description-romain'>
      <p>{description.content}</p>
    </div>
  );
}

export default DescriptionAccueil;
