import { createClient } from '@sanity/client';

const client = createClient({
  projectId: '7192h1eg',
  dataset: 'default',
  apiVersion: '2023-10-06',
  token: '',
  useCdn: true,
});

export default client;
