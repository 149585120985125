import React, { useEffect, useState } from 'react';
import client from '../../client.js';
import './Experiences.css';

function Experiences() {
  const [experiencesData, setExperiencesData] = useState(null);

  const parseDate = (dateStr) => {
    const parts = dateStr.split(' ');
    return parseInt(parts[parts.length - 1], 10);
  };

  useEffect(() => {
    client
      .fetch(`*[_type == "experiences"]{
        title,
        dates,
        content
      }`)
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          const dateA = parseDate(a.dates);
          const dateB = parseDate(b.dates);

          return dateB - dateA;
        });

        setExperiencesData(sortedData);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <>
      {experiencesData ? (
        experiencesData.map((experience, index) => (
          <div key={index} className='single-experience'>
            <h2>{experience.title}</h2>
            <div className='tag experience'>{experience.dates}</div>
            <p>{experience.content}</p>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default Experiences;
