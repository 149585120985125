import React, { useEffect, useState } from 'react';
import client from '../../client.js';
import './Formations.css';

function Formations() {
  const [formationData, setDataFormation] = useState(null);

  const parseDate = (dateStr) => {
    const parts = dateStr.split(' ');
    return parseInt(parts[parts.length - 1], 10);
  };

  useEffect(() => {
    client
      .fetch(`*[_type == "formations"]{
        title,
        dates,
        content
      }`)
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          const dateA = parseDate(a.dates);
          const dateB = parseDate(b.dates);

          return dateB - dateA;
        });

        setDataFormation(sortedData);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <>
      {formationData ? (
        formationData.map((formation, index) => (
          <div key={index} className='single-formation'>
            <h2>{formation.title}</h2>
            <div className='tag formation'>{formation.dates}</div>
            <p>{formation.content}</p>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default Formations;
