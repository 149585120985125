import React, { useEffect } from 'react';
import DescriptionAccueil from '../../components/DescriptionAccueil/DescriptionAccueil.js';
import Experiences from '../../components/Experiences/Experiences.js';
import Formations from '../../components/Formations/Formations.js';
import Navbar from "../../components/Navbar/Navbar";
import Skills from '../../components/Skills/Skills.js';
import './Home.css';


function Home () {
    const styleObject = { enableBackground: 'new 0 0 31.685 31.685', fill: '#fca311'};

    useEffect(() => {
        document.getElementById("tel").innerText = ["07", "83", "25", "81", "48"].join(".");
        document.getElementById("email").innerHTML = `<a href="mailto:${["contact", "romaincochin.fr"].join("@")}">${["contact", "romaincochin.fr"].join("@")}</a>`;
    },[])
    

    return (
        <div id="content">
            <Navbar />
            <div id='main'>
                <DescriptionAccueil />
                <div className='columns-container inline'>
                    <div className='col-1 skills-container'>
                    <Skills />
                    </div>
                    <div className='col-2 infos-container'>
                        <div className='columns-container incolumn'>
                            <div className='col-1 experiences-container'>
                                <h2>Expérience professionnelle</h2>
                                <Experiences />
                            </div>
                            <div className='col-2 formations-container'>
                                <h2>Formations</h2>
                                <Formations />
                            </div>
                            <div className='col-2 contact-container'>
                                <h2>Contact</h2>
                                <div className='contact-wrapper' id='contact'>
                                    <div className='phone-container'>
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.685 31.685" style={styleObject} xmlSpace="preserve">
                                            <g>
                                                <path d="M22.507,0H9.175C7.9,0,6.87,1.034,6.87,2.309v27.07c0,1.271,1.03,2.306,2.305,2.306h13.332   c1.273,0,2.307-1.034,2.307-2.306V2.309C24.813,1.034,23.78,0,22.507,0z M23.085,25.672H8.599V3.895h14.486V25.672z M18.932,2.343   h-6.181V1.669h6.182L18.932,2.343L18.932,2.343z M21.577,2.035c0,0.326-0.266,0.59-0.591,0.59c-0.326,0-0.591-0.265-0.591-0.59   s0.265-0.59,0.591-0.59C21.312,1.444,21.577,1.709,21.577,2.035z M18.655,29.225h-5.629v-1.732h5.629V29.225z" style={{ fill: '#FCA311' }} />
                                            </g>
                                        </svg>
                                        <p id="tel"></p>
                                    </div>
                                    <div className='mail-container'>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={styleObject} xmlSpace="preserve">
                                        <g>
                                            <g>
                                            <path d="M507.49,101.721L352.211,256L507.49,410.279c2.807-5.867,4.51-12.353,4.51-19.279V121C512,114.073,510.297,107.588,507.49,101.721z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                            <path d="M467,76H45c-6.927,0-13.412,1.703-19.279,4.51l198.463,197.463c17.548,17.548,46.084,17.548,63.632,0L486.279,80.51C480.412,77.703,473.927,76,467,76z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                            <path d="M4.51,101.721C1.703,107.588,0,114.073,0,121v270c0,6.927,1.703,13.413,4.51,19.279L159.789,256L4.51,101.721z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                            <path d="M331,277.211l-21.973,21.973c-29.239,29.239-76.816,29.239-106.055,0L181,277.211L25.721,431.49C31.588,434.297,38.073,436,45,436h422c6.927,0,13.412-1.703,19.279-4.51L331,277.211z"/>
                                            </g>
                                        </g>
                                        </svg>
                                        <span id="email"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;