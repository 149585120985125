import React, { useEffect, useState } from 'react';
import client from '../../client.js';

function Skills () {
    const [skillsData, setSkillsData] = useState(null);

    useEffect(() => {
        client
        .fetch(`*[_type == "competences"]{
            title,
            "skills": skills[]{
                skillName,
                "skillLogoUrl": skillLogo.asset->url,
                skillDesc
            }
        }`)            
        .then((data) => {
            setSkillsData(data);
        })
        .catch(error => {
            console.error("Error fetching data: ", error);
        }); 
    }, []);

    return (
        <>
        {skillsData ? (
            skillsData.map((category, index) => (
                <div key={index} className='skill-category-container'>
                <h2>{category.title}</h2>
                {category.skills ? (
                    category.skills
                    .filter(skill => skill) 
                    .map((skill, skillIndex) => (
                        <div key={skillIndex} className='single-skill'>
                        { skill.skillLogoUrl && <img src={skill.skillLogoUrl} alt={skill.skillName} /> }
                        <div className='skillInfos'>
                            <h3>{skill.skillName}</h3>
                            <p>{skill.skillDesc}</p>
                        </div>
                        </div>
                    ))
                ) : <p>No skills listed for this category.</p>}
                </div>
            ))
            ) : (
            <div>Loading...</div>
        )}
        </>
    )
}

export default Skills;